







































































import { defineComponent, ref, Ref } from '@vue/composition-api';
import { Inp, Btn } from '@/components/UI';
interface Param {
	search: string;
	area: string;
	method: string;
}
interface MapArea {
	key: string;
	defaultText: string;
}
export default defineComponent({
	components: { Inp, Btn },
	props: {
		param: {
			type: Object,
			required: true,
		},
		isIndexPage: {
			type: Boolean,
			required: true,
		},
		mapArea: {
			type: Array,
			required: true,
			validator(value) {
				return !!(Array.isArray(value) && value.length > 0 && typeof value[0].key === 'string' && typeof value[0].defaultText === 'string');
			},
		},
	},
	setup(props: any, { root }: any) {
		const { param } = props;
		const { $router, $message } = root;
		const t = root.$t.bind(root);
		const { search: searchtext, area: areaText, method: methodText } = param as Param;
		const search: Ref<string> = ref(searchtext || '');
		const area: Ref<string> = ref(areaText || 'blog');
		const method: Ref<string> = ref(['GET', 'POST'].includes(('' + methodText).toUpperCase()) ? ('' + methodText).toUpperCase() : 'GET');
		const submit = (event: Event) => {
			if (search.value === '' || search.value.trim() === '' || typeof search.value !== 'string') {
				$message.warning(t('partSearch.noInput')); //'请输入关键词'
				return;
			}
			$router.push({
				query: { search: search.value, area: area.value, method: method.value },
			});
		};
		const mapAreaArr: Ref<MapArea[]> = ref(props.mapArea);
		return { search, area, method, submit, t, mapAreaArr };
	},
});
