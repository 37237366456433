import { Data, defineComponent, reactive, ref, Ref, toRefs, watch } from '@vue/composition-api';

import Card from '@/components/Card';
import Empty from '@/components/UI/Empty';
import FetchData from '@/components/FetchData';
import SearchBox from './search-box';
import SearchEmpty from './search-empty';
import { parseTime } from '@/public/methods';

interface MapArea {
	key: string;
	defaultText: string;
}
interface SearchHistory {
	text: string;
	area: string | undefined;
	method: string | undefined;
	timeStamp?: number;
}
interface SearchHot {
	onlyid: string | number;
	text: string;
}
interface BaseOnlyid {
	onlyid: string | number;
	[propName: string]: any;
}
interface BlogData extends BaseOnlyid {
	title: string;
	caption: string;
	tag?: string | string[];
	time?: number;
}
interface PhotoData extends BaseOnlyid {
	title: string;
	imgUrl: string;
	albumOnlyid: string | number;
	albumTitle: string;
	time?: number;
}
interface MusicData extends BaseOnlyid {
	title: string;
	author: string;
	album: string;
	time?: number;
}
interface FlashData extends BaseOnlyid {
	title: string;
	description: string;
	author: string;
	playCount: number;
	praiseCount: number;
	backImg: string;
	time: number;
}
interface SearchResultData {
	onlyid: string | number;
	type: string;
	time: string;
	data: BlogData | PhotoData | MusicData | FlashData;
}
interface SearchResult {
	page: number;
	totalPage: number;
	everyPageCount: number;
	totalResultCount: number;
	keywords: string[] | string;
	useTime: number;
	data: SearchResultData[];
}

const storageHisName = 'sheaneh-search-d-history';
export default defineComponent({
	components: { SearchBox, SearchEmpty, Card, Empty, FetchData },
	directives: {
		wordshigh(el, binding) {
			let { keyword } = binding.value;
			keyword = ('' + keyword).replace(/\.|@|#|\$|%|\^|&|\*|\(|\)|\?/g, '');
			if (typeof ''.replaceAll !== 'function') {
				String.prototype.replaceAll = function (oldStr: any, newStr: any) {
					return this.replace(new RegExp(oldStr, 'g'), newStr);
				};
			}
			if (('' + keyword).includes('<') || ('' + keyword).includes('>')) {
				keyword = ('' + keyword).replace('.', '').replaceAll('<', '').replaceAll('>', '');
			}
			const keywords = keyword.split(' ');
			//console.log(keywords);
			el.querySelectorAll('[data-color-change-for-add]').forEach((element: Element) => {
				keywords.forEach((item: string) => {
					if (item !== '') {
						element.innerHTML = element.innerHTML.replaceAll(item, `<font color="red">${item}</font>`);
					}
				});
			});
			//el.innerHTML = el.innerHTML.replaceAll(keyword, `<font color="red">${keyword}</font>`);
		},
		hackwidth(el) {
			/*
			const pWidth = el.parentElement?.clientWidth;
			console.log(pWidth);
			el.style.width = pWidth + 'px';
			*/
			/*
			console.log('el', el.parentElement);
			const imgEl = el.parentElement?.querySelector('img[data-sach-img]');
			console.log('imgEl', imgEl);
			if (imgEl) {
				imgEl.addEventListener('load', function (event: Event) {
					const srcElement = event.target || event.srcElement;
					console.log('srcElement', srcElement);
					if (srcElement) {
						const width = srcElement;
						console.log(width);
					}
				});
			}
			*/
			const imgEl = el.querySelector('img[data-hackw-img]');
			const srcEl = el.querySelector('[data-hackw-p]');
			//console.log(imgEl, srcEl);
			imgEl?.addEventListener('load', (event: Event) => {
				const imgEl = event.target || event.srcElement;
				//console.log('加载好了', imgEl);
				if (imgEl && srcEl) {
					//imgEl as Element;
					const width = (imgEl as Element).clientWidth;
					srcEl.style.width = width + 'px';
				}
			});
		},
	},
	setup(props: any, { root }: any) {
		const { $route, $router } = root;
		const t = root.$t.bind(root);
		const { search, area, method, page } = $route.query;
		const searchData: Data = reactive({ search, area, method, page });
		const mapArea: Ref<MapArea[]> = ref([
			{ key: 'blog', defaultText: '博文' },
			{ key: 'photo', defaultText: '相册' },
			{ key: 'music', defaultText: '音乐' },
			{ key: 'flash', defaultText: 'Flash' },
		]);
		const isNewWindow: Ref<boolean> = ref(false);
		//功能块：搜索历史记录
		const searchHistory: Ref<SearchHistory[]> = ref([]);
		const deleteSearchHistory = (key: number | boolean) => {
			if (key === true) {
				if (confirm('确定清空搜索历史记录吗？')) {
					localStorage.setItem(storageHisName, '[]');
					searchHistory.value = [];
				}
			} else {
				key = Number(key);
				if (isNaN(key)) {
					return;
				}
				if (searchHistory.value[key]) {
					searchHistory.value.splice(key, 1);
					localStorage.setItem(storageHisName, JSON.stringify(searchHistory.value));
				}
			}
			//$router.push({ query: { ...$route, _k: Math.random() } });
		};
		(() => {
			//console.log('调用闭包');
			if (window.localStorage && typeof localStorage === 'object' && typeof localStorage.setItem === 'function' && typeof localStorage.getItem === 'function') {
				//console.log('支持本地存储');
				let h = JSON.parse(localStorage.getItem(storageHisName) || '[]');
				let match = true;
				if (Array.isArray(h)) {
					h.forEach((item: any) => {
						if (typeof item !== 'object' || typeof item.text !== 'string') {
							match = false;
						}
					});
				} else {
					match = false;
				}
				if (!match) {
					h = [];
				}
				if (searchData.search && typeof searchData.search === 'string' && searchData.search.trim() !== '') {
					const findIndex = h.findIndex((item: any) => item?.text === searchData.search);
					if (findIndex >= 0) {
						h.splice(findIndex, 1);
					}
					h.unshift({ text: searchData.search, ...{ area: searchData.area || 'blog' }, ...{ method: searchData.method || 'GET' }, timeStamp: Math.floor(new Date().getTime() / 1000) });
				}
				if (h.length > 8) {
					h.length = 8;
				}
				if (match) {
					searchHistory.value = h;
				}
				localStorage.setItem(storageHisName, JSON.stringify(h));
			}
		})();
		//功能块：热门搜索
		const searchHot: Ref<SearchHot[]> = ref([]);
		const touchSearchHotData = (data: any) => (searchHot.value = data);
		//搜索结果
		const searchResult: Ref<SearchResult> = ref({
			page: 0,
			totalPage: 0,
			everyPageCount: 15,
			totalResultCount: 0,
			useTime: 0,
			keywords: [],
			data: [],
		});
		const touchSearchResult = (data: any) => {
			//console.log(data);
			searchResult.value = data;
		};
		return { ...toRefs(searchData), t, mapArea, isNewWindow, searchHistory, deleteSearchHistory, searchHot, touchSearchHotData, searchResult, touchSearchResult, parseTime };
	},
});

/**
 * 
 * 
 * [
											{
												onlyid: 'd53cc3a8dd7b374f77d41dd7f30ee982be0ed5b3',
												title: '青青子衿，悠悠我心',
												caption: '这世上从来没有哪个博客能像悠悠博客一样，让我连续夸了两年之久还在持续。',
												tag: ['悠悠', '女程序员', '随笔'],
											},
											{
												onlyid: 'dac8b482e1e0598f02f3e1641b3b2cc70ad3e4b8',
												title: '【转载】余生不长，请好好活',
												caption:
													'童年转瞬即逝，青春一晃过去，快乐和幸福总是短暂的，那些无忧无虑的岁月一去不复返，剩下的时间，都是余生。优化建议 1.样式1 如下表现为 文章UI居中 以及整体居中以及整体居中以及整体居中以及整体居中以及整体居中以及整体居中以及整体居中以及整体居中以及整体居中以及整体居中 2.样式2 如下表现为 文章UI全覆盖 整体覆盖浏览器 本次修改仅收集建议 如果还有任何建议的话 可以在评论区提出 我会收集并判断 如果觉得可...',
												tag: '美文',
											},
										][
											{
												onlyid: 'd_b05eb917b307bf83134d03a754103c69',
												title: '忘忧',
												author: '丽江小倩',
												album: '未知',
											},
											{
												onlyid: 'd_c956b8bbe8f4b217c20b3b2e3ed99184',
												title: '琉璃青春',
												author: '丽江小倩',
												album: '未知',
											},
										][
											{
												onlyid: 'uaqj',
												title: '青春校园1',
												imgUrl: '/static/testimg/photo/200803a.jpg',
												albumOnlyid: 'sduyjk478jhsdjh',
											},
											{
												onlyid: 'uiewjk',
												title: '青春校园2',
												imgUrl: '/static/testimg/photo/200713j.jpg',
												albumOnlyid: 'sduyjk478jhsdjh',
											},
										]
 */
