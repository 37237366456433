






import { defineComponent } from '@vue/composition-api';
import { Empty } from '@/components/UI';

export default defineComponent({
	components: { Empty },
	setup() {
		return {};
	},
});
